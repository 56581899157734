.select {
  position: relative;
  transition: opacity 0.3s ease;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    width: 220px;
  }
  @media screen and (min-width: 1220px) {
    width: 245px;
  }

  &.-focused {
    .field {
      &::after {
        transform: translate(0%, -50%) rotate(180deg);
      }
    }

    .options {
      display: block;
      z-index: 1;
    }
  }

  &.-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {

    .border {
      height: 3px;
    }
  }

  .border {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: #FFCC00;
  }

  .label {
    font-weight: 600;
    line-height: 15px;
    font-size: 0.75rem;
    color: #323232;
  }
  .field {
    position: relative;
    margin-top: 4px;

    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 7px;
      position: absolute;
      top: calc(50% - 7px);
      right: 0px;
      background-image: url(../img/seta.svg);
      transition: transform 0.3s ease;
      transform: translate(0%, -50%);
    }
  }
  .selected {
    position: relative;
    padding-bottom: 7px;
    background: transparent;
    border: 0px;
    font-weight: 600;
    line-height: 19px;
    font-size: 1rem;
    color: #323232;
    outline: none;

    &.-empty {
      &::placeholder {
        color: rgba(50,50,50,0.5);
      }
    }

    &::placeholder {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      line-height: 19px;
      font-size: 1rem;
      color: #323232;
    }
  }
  .options {
    display: none;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0px;
    padding-top: 7px;
    background-color: #FFFFFF;
    border-radius: 9px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
  }
  .option {
    display: block;
    padding: 7px 14px;
    text-decoration: none;
    font-weight: 600;
    line-height: 18px;
    font-size: 0.875rem;
    color: #323232;
    transition: background-color 0.3s ease;

    &:last-child {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
    }

    &:hover {
      background-color: rgba(255,204,0,0.1);
    }
  }
  .simplebar-scrollbar {

    &.simplebar-visible {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      background-color: #FFCC00;
    }

    .simplebar-track {
      right: 7px;
    }
  }
}
