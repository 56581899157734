.offers {
  width: fit-content;
  padding: 48px;
  margin-top: 32px;
  background-color: #FFFFFF;
  border-radius: 9px;

  @media screen and (max-width: 768px) {
    padding: 48px 15px 15px 15px;
  }
}
