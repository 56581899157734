.textField {
  position: relative;
  transition: opacity 0.3s ease;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    width: 245px;
  }

  &.-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.-reduced {
    max-width: 110px;
  }

  &.-error {
    .border {
      background-color:#D32F2F !important;
    }
  }

  &:hover {

    .border {
      height: 3px;
    }
  }

  .border {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: #FFCC00;
  }

  .border-x2{
    width: 211%;
  }

  .label {
    font-weight: 600;
    line-height: 15px;
    font-size: 0.75rem;
    color: #323232;
  }
  .field {
    width: 100%;
    margin-top: 4px;
    padding-bottom: 7px;
    background-color: transparent;
    border: 0px;
    font-family: 'Montserrat', sans-serif;
    line-height: 19px;
    font-size: 1rem;
    color: #323232;
    outline: none;

    &:not(.-empty) {
      text-transform: uppercase;
      font-weight: 600;
    }

    &.-empty {
      font-weight: 500;
    }   
  }

  input[type=file] {
    width: 211%;
  }
}