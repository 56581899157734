.solicitacao {
  display: flex;
  justify-content: space-between;
  padding-top: 49px;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    width: calc(100% - 16vw);
    max-width: 600px;
    margin: 0px auto;
  }

  .container {
    @media screen and (min-width: 769px) {
      max-width: 545px;
    }
  }
  .title {
    margin-bottom: 14px;
    line-height: 44px;
    font-weight: 700;
    font-size: 2.25rem;
    color: #323232;

    @media screen and (max-width: 768px) {
      line-height: 27px;
      font-size: 1.35rem;
    }
  }
  .text {
    margin-bottom: 27px;
    line-height: 20px;
    font-weight: 500;
    font-size: 0.875rem;
    color: #323232;
    .important{
      font-weight: 900;     
    }
  }
  .text-2 {
    margin: 5px 0px;
  }
  .form {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.-singleColumn {
      display: block;

      .textField {
        margin: 0px 0px 28px 0px;
      }
    }
  }
  .textField {
    margin-right: 27px;

    @media screen and (max-width: 768px) {
      margin-bottom: 28px;
    }
  }
  .figure {
    position: relative;
    width: 300px;
    height: 300px;

    @media screen and (max-width: 768px) {
      width: 150px;
      height: 150px;
      margin-bottom: 28px;
    }

    &.-error {
      &::after {
        content: "";
        position: absolute;
        z-index: 2;
        top: 225px;
        left: 25px;
        display: block;
        width: 41px;
        height: 41px;
        background-image: url(../img/error.png);

        @media screen and (max-width: 768px) {
          top: 110px;
          left: 5px;
        }
      }
    }

    .bg {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: calc(50% - 11px);
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
    .img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      background-image: url(../img/documento.png);
      background-repeat: no-repeat;
      background-size: 300px;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 768px) {
        background-size: 150px;
      }
    }
  }

  .pb10 {
    padding-bottom: 10px;
  }
}
