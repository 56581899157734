.tour-modal {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 1300px;
    height: 90%;
    max-height: 586px;
    background-color: #141414;
    border-radius: 9px;
    overflow: hidden;
    transform: translate(-50%, -50%);
  }
  .close {
    position: absolute;
    z-index: 2;
    width: 41px;
    height: 41px;
    background-image: url(../img/close.png);
    text-indent: -9000px;
    overflow: hidden;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      top: 28px;
      right: 28px;
    }
    @media screen and (min-width: 769px) {
      top: 56px;
      right: 56px;
    }
  }
  .step {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.step-1 {
      .hero {
        background-image: url(../img/tour/tour-hero-step-1.jpg);
      }
    }
    &.step-2 {
      .hero {
        background-image: url(../img/tour/tour-hero-step-2.jpg);
      }
    }
    &.step-3 {
      .hero {
        background-image: url(../img/tour/tour-hero-step-3.jpg);
      }
    }
    &.step-4 {
      .hero {
        background-image: url(../img/tour/tour-hero-step-4.jpg);
      }
    }
    &.step-5 {
      .hero {
        background-image: url(../img/tour/tour-hero-step-5.jpg);
      }
    }
    &.step-6 {
      .hero {
        background-image: url(../img/tour/tour-hero-step-6.jpg);
      }
    }
  }
  .hero {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    background-color: #FFCC00;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 40%;
      padding: 28px;
      background-size: 250px;
    }
    @media screen and (min-width: 769px) {
      padding: 56px;
    }
    @media screen and (min-width: 769px) and (max-width: 1023px) {
      background-size: 300px;
    }
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 100%;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
      width: 100%;
      height: 60%;
      padding: 28px;
    }
    @media screen and (min-width: 769px) {
      padding: 56px;
    }
  }
  .number {
    margin-bottom: 28px;
    font-weight: 700;
    line-height: 78px;
    font-size: 64px;
    color: #FFFFFF;

    @media screen and (max-width: 768px) {
      margin-bottom: 14px;
      line-height: 48px;
      font-size: 48px;
    }

    .highlight {
      color: #FFCC00;
    }
  }
  .title {
    margin-bottom: 14px;
    font-weight: 700;
    line-height: 24px;
    font-size: 20px;
    color: #FFFFFF;
  }
  .text {
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    color: #FFFFFF;
  }
  .bullets {
    display: flex;
    position: absolute;
    left: 56px;
    bottom: 76px;

    @media screen and (max-width: 768px) {
      left: 50%;
      bottom: 48px;
      transform: translate(-50%, 0);
    }
  }
  .bullet {
    width: 6px;
    height: 6px;
    margin-right: 14px;
    background-color: #FFFFFF;
    border-radius: 50%;
    opacity: 0.5;

    &:last-child {
      margin-right: 0px;
    }

    &.-selected {
      width: 15px;
      border-radius: 9px;
      opacity: 1;
    }
  }
  .navigate {
    position: absolute;
    z-index: 2;
    bottom: 56px;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;

    @media screen and (max-width: 768px) {
      bottom: 28px;
    }

    &.-next {
      right: 56px;
      background-image: url(../img/tour/arrow-next.svg);

      @media screen and (max-width: 768px) {
        right: 28px;
      }
    }
    &.-prev {
      left: 56px;
      background-image: url(../img/tour/arrow-prev.svg);

      @media screen and (max-width: 768px) {
        left: 28px;
      }
    }
  }
  .button {
    position: absolute;
    right: 56px;
    bottom: 56px;

    @media screen and (max-width: 768px) {
      height: 40px;
      right: 28px;
      bottom: 28px;
      font-size: 0.875rem;
    }
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
}
