.checkField {
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 36px;
  font-weight: 600;
  line-height: 19px;
  font-size: 1rem;
  color: #323232;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    margin-bottom: 14px;
  }
  @media screen and (min-width: 769px) {
    margin-right: 27px;
  }

  &.-selected {
    .after {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 4px;
      display: block;
      width: 18px;
      height: 18px;
      background-color: #FFCC00;
      border-radius: 3px;
      transform: translate(0%, -50%);
    }
  }

  .before {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 0px;
    display: block;
    width: 26px;
    height: 26px;
    border: 2px solid #FFCC00;
    border-radius: 6px;
    transform: translate(0%, -50%);
  }

  .radioInput {
    display: none;
  }
}
