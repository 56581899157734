.searchBar {
  position: absolute;
  z-index: 1;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-top: 5px solid #FFCC00;
  border-radius: 9px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
  transform: translate(-50%, 0);

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    width: calc(100% - 8vw);
    max-width: 600px;
    top: -152px;
    padding: 27px 14px;

    .select {
      margin-right: 0px;
      margin-bottom: 27px;
    }
  }
  @media screen and (min-width: 1024px) {
    height: 100px;
    top: -78px;
    padding: 22px 14px 27px 14px;

    .select {
      margin-right: 14px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
  @media screen and (min-width: 1220px) {
    padding: 22px 27px 27px 27px;

    .select {
      margin-right: 27px;
    }
  }

  .separator {
    @media screen and (max-width: 1023px) {
      width: 100%;
      margin-top: 14px;
    }
    @media screen and (min-width: 1024px) {
      position: relative;
      margin-left: 27px;

      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        height: 75px;
        top: 50%;
        left: -14px;
        background-color: #C8C8C8;
        transform: translate(0%, -50%);
      }
    }
    @media screen and (min-width: 1220px) {
      margin-left: 54px;

      &::before {
        left: -27px;
      }
    }
  }
}
