.header {
  position: relative;
  background-image: linear-gradient(#323232, #000000);

  @media screen and (max-width: 1023px) {
    height: 389px;
  }
  @media screen and (min-width: 1024px) {
    height: 364px;
  }

  &.-reduced {
    height: 140px;
  }

  .textContainer {
    position: absolute;
    left: 50%;
    text-align: center;
    transform: translate(-50%, 0);

    @media screen and (max-width: 1023px) {
      width: calc(100% - 8vw);
      top: 108px;
    }
    @media screen and (min-width: 1024px) {
      top: 154px;
    }
  }
  .textContainer-title {
    margin-bottom: 7px;
    font-weight: 700;
    color: #FFCC00;

    @media screen and (max-width: 1023px) {
      white-space: initial;
      line-height: 34px;
      font-size: 1.75rem;
    }
    @media screen and (min-width: 1024px) {
      white-space: nowrap;
      line-height: 78px;
      font-size: 4rem;
    }
  }
  .textContainer-subtitle {
    line-height: 20px;
    font-weight: 700;
    color: #FFFFFF;

    @media screen and (max-width: 1023px) {
      font-size: 1.125rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }
  .logo {
    position: absolute;

    @media screen and (max-width: 768px) {
      max-width: 32vw;
    }
    @media screen and (min-width: 769px) {
      max-width: 16vw;
    }
    @media screen and (max-width: 1023px) {
      top: 4vw;
      left: 4vw;
    }
    @media screen and (min-width: 1024px) {
      top: 2vw;
      left: 2vw;
      max-width: 150px;
    }
  }
  .tourWidget {
    position: absolute;

    @media screen and (max-width: 1023px) {
      top: 4vw;
      right: 4vw;
    }
    @media screen and (min-width: 1024px) {
      top: 2vw;
      right: 2vw;
    }
  }
  .link {
    font-weight: 700;
    line-height: 20px;
    color: #FFFFFF;
  }
  .aceiteCookies {
    text-align: center;
  }
}
