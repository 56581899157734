.summaryContainer {
  .orderSummary {
    display: flex;
    margin-top: 27px;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
    }

    .column {
      width: 100%;
      max-width: 355px;
      padding: 27px;
      margin-right: 27px;
      background-color: #FFFFFF;
      border-radius: 9px;

      @media screen and (max-width: 1280px) {
        margin: 0px 0px 28px 0px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      &.-reduced {
        .item {
          margin-bottom: 7px;
        }
        .columnTitle:last-child {
          margin-bottom: 7px;
        }
      }
    }
    .columnTitle {
      margin-bottom: 14px;
      font-weight: 600;
      line-height: 15px;
      font-size: 0.75rem;
      color: #323232;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;

      &.-edit {
        float: left;
        padding-top: 20px;
        padding-left: 20px;

        button {
          background-image: url(../img/calendar-edit.png);
          height: 54px;
          width: 54px;
        }
      }
      
      &.-divisor {
        margin-top: 14px;
        padding-top: 14px;
        border-top: 1px solid #D2D2D2;
      }
      
      &.-plates {
        display: flex;
        justify-content: center;
        margin: 60px 0px;
      }
      
      &.-period {
        display: block;
        width: 110px;
        height: 123px;
        border: 3px solid #FFCC00;
        border-radius: 9px;
        float: left;

        .date {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 47px;
          background-color: #FFFFFF;
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
          font-weight: 600;
          line-height: 19px;
          font-size: 1rem;
          color: #323232;

          .name {
            font-size: 0.875rem;
          }
        }
        .time {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 47px;
          background-color: #FFCC00;
          font-weight: 600;
          line-height: 18px;
          font-size: 0.875rem;
          color: #323232;
        }
        .edit {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 23px;
          background-color: #FFCC00;
          font-weight: 600;
          line-height: 18px;
          font-size: 0.875rem;
          color: #323232;
          
          .buttonEdit{
            width: fit-content;
            /* margin: 14px auto 0px auto; */
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            padding: 10px 12px;
            border: 0px;
            border-radius: 9px;
            font-weight: 600;
            line-height: 19px;
            font-size: 1rem;
            cursor: pointer;
            transition: all 0.3s ease;
            white-space: nowrap;
            outline: none;            
          }
        }        
      }
      
      &.-description {
        clear: both;
      }
    }
    .plate {
      font-family: 'Squada One', sans-serif;
      line-height: 18px;
      letter-spacing: 0.75px;
      font-weight: 400;
      font-size: 1.4rem;
      color: #959595;
    }
    .description {
      max-width: 235px;
      font-weight: 500;
      line-height: 20px;
      font-size: 0.875rem;
      color: #323232;
    }
    .value {
      font-weight: 600;
      line-height: 15px;
      font-size: 0.75rem;
      color: #323232;
    }
  }
}