.paymentStepContainer {
  display: flex;
  margin-top: 27px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .orderSummary {
    width: 100%;
    max-width: 245px;
    padding: 27px;
    background-color: #FFFFFF;
    border-radius: 9px;

    @media screen and (max-width: 768px) {
      margin: 0px 0px 28px 0px;
    }
    @media screen and (min-width: 769px) {
      margin-right: 27px;
    }

    .orderSummary-title {
      margin-bottom: 27px;
      font-weight: 600;
      line-height: 15px;
      font-size: 0.75rem;
      color: #323232;
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;

      &.-divisor {
        margin-bottom: 0px;
        padding-top: 14px;
        border-top: 1px solid #D2D2D2;
      }
    }
    .description {
      max-width: 130px;
      text-transform: capitalize;
      font-weight: 500;
      line-height: 20px;
      font-size: 0.875rem;
      color: #323232;
    }
    .value {
      font-weight: 600;
      line-height: 15px;
      font-size: 0.75rem;
      color: #323232;
    }
  }
  .fields {
    @media screen and (max-width: 768px) {
      max-width: 517px;
    }
  }
  .cards {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (min-width: 769px) {
      align-items: center;
    }

    .text {
      margin-right: 14px;
    }
    .list {
      display: flex;
    }
    .item {
      margin-right: 7px;
    }
    .image {
      width: 36px;
      height: 27px;
    }
  }
  .text {
    max-width: 245px;
  }
}
