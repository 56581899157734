.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.8);

  .logo {
    width: 150px;
    margin-bottom: 27px;
  }
  .text {
    position: relative;
    font-weight: 700;
    line-height: 18px;
    font-size: 1rem;
    color: #FFFFFF;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
    @media screen and (min-width: 769px) {
      margin-top: 180px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translate(-50%, 0);
      display: block;
      width: 80px;
      height: 2px;
      background-color: #FFCC00;
    }
  }
}
