.steps {
  display: flex;

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
  @media screen and (min-width: 769px) {
    margin-bottom: 75px;
  }
  @media screen and (min-width: 769px) and (max-width: 1280px) {
    margin-top: 28px;
  }
}
