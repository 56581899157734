.searchResults {
  margin-bottom: 82px;

  @media screen and (max-width: 1023px) {
    width: calc(100% - 8vw);
  }
  @media screen and (min-width: 1024px) {
    width: calc(100% - 4vw);
  }
  @media screen and (min-width: 1220px) {
    width: 100%;
  }

  > .title {
    position: relative;
    margin-bottom: 27px;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    font-size: 1.25rem;
    color: #323232;

    .detail {
      display: block;
      width: 16px;
      height: 6px;
      position: absolute;
      left: 50%;
      bottom: -13px;
      background-color: #FFCC00;
      border-radius: 9px;
      transform: translate(-50%, 0%);
    }
  }
}
