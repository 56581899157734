.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 14px 16px;
  border: 0px;
  border-radius: 9px;
  font-weight: 700;
  line-height: 19px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  outline: none;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    min-width: 220px;
  }
  @media screen and (min-width: 1220px) {
    min-width: 246px;
  }

  &.-search {
    background-color: #FFCC00;
    color: #323232;

    &::before {
      width: 22px;
      height: 20px;
      background-image: url(../img/lupa.svg);
    }
  }

  &.-location {
    background-color: #323232;
    color: #FFFFFF;

    &::before {
      width: 15px;
      height: 22px;
      background-image: url(../img/pin.svg);
    }
  }

  &.-dark {
    background-color: #323232;
    color: #FFFFFF;
  }

  &.-yellow {
    background-color: #FFCC00;
    color: #323232;
  }

  &.-disabled {
    opacity: 0.5;
    background-color: #EAEAEA !important;
    pointer-events: none;
    filter: grayscale(100%);
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    border-radius: 9px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    transition: opacity 0.3s ease;
  }

  &::before {
    content: "";
    display: inline-block;
    margin-right: 7px;
  }

}
