.steps {
  .step {
    display: flex;
    align-items: center;

    &.-checked {
      .number {
        .after {
          background-color: #FFCC00;

          .icon {
            position: absolute;
            z-index: 3;
            top: 50%;
            left: 50%;
            display: block;
            width: 18px;
            height: 18px;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    &.-disabled {
      filter: grayscale(100%);
      pointer-events: none;

      .number,
      .name {
        color: #959595;
      }
    }

    &.step-1 {
      .name {
        max-width: 63px;
      }
    }
    &.step-2 {
      .name {
        max-width: 64px;
      }
    }
    &.step-3 {
      .name {
        max-width: 86px;
      }
    }
    &.step-4 {
      .name {
        max-width: 108px;
      }
    }
    &.step-5 {
      .name {
        max-width: 106px;
      }
    }

    &:first-child {
      .before {
        display: none;
      }
    }

    > .before {
      display: block;
      height: 3px;
      margin: 0px 7px;
      background-color: #FFCC00;
      border-radius: 1.5px;

      @media screen and (max-width: 1023px) {
        width: 10px;
      }
      @media screen and (min-width: 1024px) {
        width: 40px;
      }
    }

    .link {
      display: flex;
      align-items: center;
      text-decoration: none;

      &:hover {
        .name {
          text-decoration: underline;
        }
      }
    }

    .number {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 41px;
      height: 41px;
      margin-right: 7px;
      font-weight: 700;
      line-height: 20px;
      font-size: 1.25rem;
      color: #323232;

      @media screen and (max-width: 1023px) {
        margin-right: 0px;
      }

      .before {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        display: block;
        width: 31px;
        height: 31px;
        background-color: #FFCC00;
        opacity: 0.2;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
      .after {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        width: 41px;
        height: 41px;
        border: 2px solid #FFCC00;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        .icon {
          display: none;
        }
      }

      .layer {
        position: relative;
        z-index: 2;
      }
    }
    .name {
      font-weight: 700;
      line-height: 18px;
      font-size: 0.875rem;
      color: #323232;

      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }
}
