.consultBar {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    width: calc(100% - 16vw);
    max-width: 600px;

    .submitButton {
      margin-top: 27px;
    }
  }
  @media screen and (min-width: 1024px) {
    width: calc(100% - 8vw);
    max-width: 1090px;

    .textField {
      margin-right: 27px;
    }
  }
  @media screen and (min-width: 1220px) {
    width: 100%;
  }

  .content {
    @media screen and (min-width: 1024px) {
      max-width: 520px;
      margin-right: 27px;
    }
  }
  .form {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1023px) {
      flex-direction: column;
      width: 100%;
      padding-top: 28px;
    }
    @media screen and (min-width: 1024px) {
      padding-top: 40px;
    }
  }
  .title {
    position: relative;
    margin-bottom: 27px;
    font-weight: 700;
    line-height: 20px;
    font-size: 1.25rem;
    color: #323232;

    .detail {
      display: block;
      width: 16px;
      height: 6px;
      position: absolute;
      left: 0px;
      bottom: -13px;
      background-color: #FFCC00;
      border-radius: 9px;
    }
  }
  .text {
    font-weight: 500;
    line-height: 20px;
    font-size: 0.875rem;
    color: #323232;

    @media screen and (max-width: 1023px) {
      letter-spacing: -0.1px;
    }
  }
}
