.card {
  position: relative;
  min-height: 245px;
  padding: 27px;
  background-color: #FFFFFF;
  border-radius: 9px;
  text-decoration: none;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 14px;
  }
  @media screen and (min-width: 769px) and (max-width: 1023px) {
    width: calc(50% - 7px);
    margin-bottom: 14px;

    &:nth-child(2n+1) {
      margin: 0px 14px 14px 0px;
    }
  }
  @media screen and (min-width: 1024px) {
    width: calc(33.33% - 10px);
    margin-bottom: 15px;

    &:nth-child(3n+2) {
      margin: 0px 15px 15px 15px;
    }
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    border-radius: 9px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    transition: opacity 0.3s ease;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    margin-bottom: 14px;
    background-color: hsl(0, 0%, 95%);
    border-radius: 9px;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    font-size: 1.25rem;
    color: #323232;

    &.-disabled {
      opacity: 0.5;
    }
  }
  .name {
    font-weight: 700;
    line-height: 20px;
    font-size: 1rem;
    color: #323232;
  }
  .field {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  .cnpj {
    font-weight: 500;
    line-height: 20px;
    font-size: 0.75rem;
    color: #323232;
  }
  .address {
    word-break: break-all;
    font-weight: 500;
    line-height: 20px;
    font-size: 0.875rem;
    color: #323232;

    .km{
      word-break: normal;
      font-weight: 900 !important;     
    }
  }
}
