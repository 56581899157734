html,
body {
  height: 100%;
}
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #FAFAFA;
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    min-height: calc(100vh - 389px - 305px);
    padding: 220px 0px 56px 0px;

    &.-reduced {
      min-height: calc(100vh - 140px - 305px);
      padding: 120px 0px 56px 0px;
    }
  }
  @media screen and (min-width: 1024px) {
    min-height: calc(100vh - 364px - 183px);
    padding: 51px 0px 82px 0px;

    &.-reduced {
      min-height: calc(100vh - 140px - 183px);
    }
  }
}
.desktopOnly {
  @media screen and (max-width: 1023px) {
    display: none;
  }
}
.mobileOnly {
  @media screen and (min-width: 1024px) {
    display: none;
  }
}
