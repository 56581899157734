.paymentFeedbackMessage {
  width: 100%;
  max-width: 520px;

  .text {

    &.-spacing {
      margin-top: 27px;
    }
  }
}
