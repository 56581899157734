.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    min-height: 305px;
    padding: 8vw;

    .tourWidget {
      margin-bottom: 27px;
    }
  }
  @media screen and (min-width: 1024px) {
    height: 183px;
    padding: 2vw;
  }

  .textContainer {
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }
  .brandContainer {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1023px) {
      margin-bottom: 27px;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 14px;
    }
  }
  .socialContainer {
    display: flex;
    align-items: center;
    border-left: 1px solid #C8C8C8;

    @media screen and (max-width: 1023px) {
      height: 26px;
      margin-left: 14px;
      padding-left: 14px;
    }
    @media screen and (min-width: 1024px) {
      height: 41px;
      margin-left: 27px;
      padding-left: 27px;
    }
  }
  .socialLink {
    display: block;

    @media screen and (max-width: 1023px) {
      width: 22px;
      height: 22px;
      margin-right: 14px;

      > svg {
        width: 22px;
        height: 22px;
      }
    }
    @media screen and (min-width: 1024px) {
      width: 28px;
      height: 28px;
      margin-right: 27px;
    }

    &:last-child {
      margin-right: 0px;
    }

    > svg {
      &:hover {
        g {
          fill: #FFCC00;
        }
      }
      g {
        fill: #323232;
        transition: fill 0.3s ease;
      }
    }
  }
  .logo {
    @media screen and (max-width: 1023px) {
      max-width: 111px;
    }
  }
  .support-links {
    display: flex;
    margin-top: 14px;
  }
  .link-spacer {
    margin: 0px 7px;
  }
  .link {
    text-decoration: none;
    font-weight: 700;
    line-height: 20px;
    font-size: 0.75rem;
    color: #323232;

    &.-whatsapp {
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 7px;
        background-image: url(../img/whatsapp.svg);
        background-size: 15px;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
  .text {
    font-weight: 500;
    line-height: 20px;
    font-size: 0.75rem;
    color: #323232;

    @media screen and (max-width: 1023px) {

      .break {
        display: block;
      }
    }
  }
}
