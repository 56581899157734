.internContainer {
  width: 100%;
  max-width: 1090px;
  margin-bottom: 80px;

  @media screen and (max-width: 1280px) {
    width: calc(100% - 8vw);
  }

  &.-no-margin {
    margin-bottom: 0px;
  }

  .title {
    position: relative;
    margin-bottom: 27px;
    font-weight: 700;
    line-height: 20px;
    font-size: 1.25rem;
    color: #323232;

    .detail {
      display: block;
      width: 16px;
      height: 6px;
      position: absolute;
      left: 0px;
      bottom: -13px;
      background-color: #FFCC00;
      border-radius: 9px;
    }
  }
  .text {
    margin-bottom: 14px;
    font-weight: 500;
    line-height: 20px;
    font-size: 0.875rem;
    color: #323232;

    @media screen and (max-width: 1023px) {
      letter-spacing: -0.1px;
    }

    .bold {
      font-weight: 900;
    }
    .warrant{
      font-weight: 900;
      color: #323232;
    }
    .warrant:visited{
      font-weight: 900;
      color: #323232;
    }
    
  }
  .fields {
    margin-bottom: 82px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  .row {
    display: flex;
    margin-top: 27px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .select,
    .textField {
      @media screen and (max-width: 768px) {
        margin-bottom: 27px;
      }
      @media screen and (min-width: 769px) {
        margin-right: 27px;
      }

      &:last-child {
        @media screen and (max-width: 768px) {
          margin-bottom: 0px;
        }
        @media screen and (min-width: 769px) {
          margin-right: 0px;
        }
      }
    }
  }
  .textField {
    .field {
      &:not(.-empty) {
        text-transform: initial;
        font-weight: initial;
      }
    }
  }
  .action-bar {
    margin-top: 82px;

    @media screen and (min-width: 769px) {
      width: fit-content;
    }
  }
}
