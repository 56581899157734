.listResults {

  > .text {
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    font-size: 0.875rem;
    color: #323232;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1144px;
    margin: 27px auto 0px auto;
    justify-content: center;
  }
  > .button {
    width: fit-content;
    margin: 14px auto 0px auto;
  }
}
