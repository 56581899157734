.cardDetail {
  position: absolute;
  z-index: 10;
  max-width: 370px;
  padding: 27px;
  background-color: #FFFFFF;
  border-radius: 9px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: calc(100% - 8vw);
    top: -40px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media screen and (min-width: 769px) {
    width: 100%;
    top: -28px;
  }
  @media screen and (min-width: 1280px) {
    top: -60px;
  }

  &.-reduced {
    padding: 14px;

    .container {
      position: relative;
      display: flex;
      align-items: center;

      .logo {
        flex-shrink: 0;
        width: 54px;
        height: 54px;
        margin: 0px 14px 0px 0px;
        font-size: 0.75rem;
      }
      .name {
        margin-top: 0px;
      }
      .link {
        position: relative;
        top: initial;
        right: initial;
        margin-top: 7px;
      }
    }
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    .link {
      text-decoration: underline;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 0;
    border-radius: 9px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
    transition: opacity 0.3s ease;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 82px;
    height: 82px;
    margin-bottom: 14px;
    background-color: hsl(0, 0%, 95%);
    border-radius: 9px;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    font-size: 1.25rem;
    color: #323232;
    object-fit: contain;

    &.-disabled {
      opacity: 0.5;
    }
  }
  .name {
    margin-top: 14px;
    line-height: 20px;
    font-weight: 700;
    font-size: 1rem;
    color: #323232;
  }
  .link {
    position: absolute;
    top: 14px;
    right: 14px;
    line-height: 15px;
    font-weight: 700;
    font-size: 0.75rem;
    color: #323232;
  }
  .field {
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
  .cnpj {
    font-weight: 500;
    line-height: 20px;
    font-size: 0.75rem;
    color: #323232;
  }
  .address {
    word-break: break-all;
    font-weight: 500;
    line-height: 20px;
    font-size: 0.875rem;
    color: #323232;
    .km{
      word-break: normal;
      font-weight: 900 !important;     
    }
  }
}
