.tourWidget {
  position: relative;
  padding: 14px 14px 14px 62px;
  background-color: #141414;
  border: 1px solid #141414;
  border-radius: 9px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.07);
  transition: 0.3s border ease;
  cursor: pointer;

  @media screen and (max-width: 1023px) {
    max-width: 186px;
  }

  &:hover {
    border: 1px solid #FFCC00;
  }

  & > svg {
    position: absolute;
    display: block;
    width: 34px;
    height: 32px;
    top: 50%;
    left: 14px;
    transform: translate(0, -50%);
  }

  > .title {
    font-weight: 700;
    line-height: 19px;
    font-size: 16px;
    color: #FFFFFF;

    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  > .subtitle {
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    color: #FFFFFF;
  }
}
