.submitButton {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  font-weight: 700;
  line-height: 19px;
  font-size: 1rem;
  color: #323232;
  cursor: pointer;
  transition: all 0.3s ease;

  @media screen and (max-width: 1023px) {
    align-self: flex-start;
  }

  &.-disabled {
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
  }

  &:hover {
    .border {
      opacity: 1;
    }
  }

  .border {
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: #FFCC00;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  > svg {
    display: block;
    width: 10px;
    height: 7px;
    position: absolute;
    top: calc(50% - 1px);
    right: -17px;
    transform: translate(0%, -50%) rotate(270deg);
    transition: background-image 0.3s ease;
  }
}
