.error-modal {
  position: fixed;
  z-index: 1000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: 800px;
    height: 90%;
    max-height: 400px;
    background-color: #141414;
    border-radius: 9px;
    transform: translate(-50%, -50%);
    text-align: center;

    @media screen and (max-width: 768px) {
      padding: 28px;
    }
    @media screen and (min-width: 769px) {
      padding: 56px;
    }
  }
  .close {
    position: absolute;
    width: 41px;
    height: 41px;
    background-image: url(../img/close.png);
    text-indent: -9000px;
    overflow: hidden;
    cursor: pointer; 

    @media screen and (max-width: 768px) {
      top: 28px;
      right: 28px;
    }
    @media screen and (min-width: 769px) {
      top: 56px;
      right: 56px;
    }
  }
  .logo {
    width: 120px;
    margin-bottom: 27px;
  }
  .title {
    margin-bottom: 56px;
    line-height: 20px;
    font-size: 24px;
    color: #FFFFFF;
  }
  .text {

    line-height: 20px;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 30px;
  }
  .support-links {
    display: flex;
    margin-top: 14px;
  }
  .link-spacer {
    margin: 0px 7px;
  }  
  .link {
    text-decoration: none;
    font-weight: 700;
    line-height: 20px;
    font-size: 0.75rem;
    color: #ffffff;

    &.-whatsapp {
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 7px;
        background-image: url(../img/whatsapp.svg);
        background-size: 15px;
        filter: invert(100%) sepia(6%) saturate(22%) hue-rotate(127deg) brightness(106%) contrast(105%);
      }
    }    

    &.-whatsapp-black {
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 7px;
        background-image: url(../img/whatsapp.svg);
        background-size: 15px;
        filter: invert(0%) sepia(1%) saturate(0%) hue-rotate(124deg) brightness(100%) contrast(102%);
      }
    }        

    &:hover {
      text-decoration: underline;
    }
  }
}
