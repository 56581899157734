.offer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .plate {
    @media screen and (max-width: 768px) {
      margin-bottom: 28px;
    }
    @media screen and (min-width: 769px) {
      margin-right: 50px;
    }
  }
  .description {
    text-transform: capitalize;
    line-height: 19px;
    font-weight: 600;
    font-size: 1rem;
    color: #323232;
  }
  .value {
    line-height: 18px;
    font-weight: 600;
    font-size: 0.875rem;
    color: #323232;
  }
}
