.schedulingContainer {
  .shopAddress {
    width: 100%;
    max-width: 370px;
    padding: 27px;
    margin: 27px 0px 82px 0px;
    background-color: #FFFFFF;
    border-radius: 9px;

    .line {
      font-weight: 500;
      line-height: 20px;
      font-size: 0.875rem;
      color: #323232;
    }
  }
  .periodSuggestion {
    .text {
      max-width: 545px;
    }
    .periods {
      display: flex;
      margin-top: 27px;

      @media screen and (max-width: 768px) {
        overflow: scroll;
      }
    }
    .period {
      width: 110px;
      margin-right: 27px;
      border: 1px solid #FFCC00;
      border-radius: 9px;
      transition: border-color 0.3s ease;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        flex-shrink: 0;
      }

      &:last-child {
        margin-right: 0px;
      }
      &.-selected {
        border: 3px solid #FFCC00;
      }
      &:hover {
        border-width: 3px;
      }
    }
    .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 46px;
      background-color: #FFFFFF;
      border-bottom: 1px solid #D2D2D2;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      font-weight: 600;
      line-height: 19px;
      font-size: 1rem;
      color: #323232;

      .name {
        font-size: 0.875rem;
      }
    }
    .time {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      border-bottom: 1px solid #D2D2D2;
      font-weight: 600;
      line-height: 18px;
      font-size: 0.75rem;
      color: #323232;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:last-child {
        border-bottom: 0px;
      }
      &.-disabled {
        opacity: 0.2;
        pointer-events: none;
      }
      &.-selected {
        background-color: #FFCC00;
      }
      &:not(.-selected) {
        &:hover {
          .background {
            opacity: 0.1;
          }
        }
      }

      .background {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: rgba(255,204,0,0.1);
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      .value {
        position: relative;
        z-index: 2;
      }
    }
    .simplebar-scrollbar {

      &.simplebar-visible {
        &::before {
          opacity: 1;
        }
      }

      &::before {
        background-color: #FFCC00;
      }

      .simplebar-track {
        right: 7px;
      }
    }
  }
}
